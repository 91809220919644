const Icons = {
  icPlus: '/assets/icons/ic-plus.svg',
  icArrowLeft: '/assets/icons/ic-arrow-left.svg',
  icArrowLeftWhite: '/assets/icons/ic-arrow-left-white.svg',
  icArrowLeftBlack: '/assets/icons/ic-arrow-left-black.svg',
  icArrowLeftPrimary: '/assets/icons/ic-arrow-left-primary.svg',
  icInfo: '/assets/icons/ic-info.svg',
  icInfoBlue: '/assets/icons/ic-info-blue-sky.svg',
  icInfoGrey: '/assets/icons/ic-info-grey.svg',
  icSettingWhite: '/assets/icons/ic-setting-white.svg',
  icHiddenWhite: '/assets/icons/ic-hidden-white.svg',
  icCopyWhite: '/assets/icons/ic-copy-white.svg',
  icCopy: '/assets/icons/ic-copy.svg',
  icCopyPrimary: '/assets/icons/ic-copy-primary.svg',
  icCopyOrangeRaya: '/assets/icons/ic-copy-orange-raya.svg',
  icFilterGray: '/assets/icons/ic-filter-gray.svg',
  icAddBalance: '/assets/icons/ic-add-balance.svg',
  icTransferBalance: '/assets/icons/ic-transfer-balance.svg',
  icDangerYellow: '/assets/icons/ic-danger-yellow.svg',

  // Saku Bisnis
  icBusinessBag: '/assets/icons/ic-business-bag.svg',
  icSaku: '/assets/icons/ic-saku.svg',
  icArrowDown: '/assets/icons/ic-arrow-down.svg',
  icArrowUp: '/assets/icons/ic-arrow-up.svg',
  icAddWithCircle: '/assets/icons/ic-add-w-circle.svg',
  icAddSucessCircle: '/assets/icons/ic-add-sucess.svg',
  icAddWithCircleGrey: '/assets/icons/ic-add-grey.svg',
  icRemoveWithCircleGrey: '/assets/icons/ic-remove-grey.svg',
  icArrowRight: '/assets/icons/ic-arrow-right.svg',
  icArrowRightWhitePrimary: '/assets/icons/ic-arrow-right-white.svg',
  icArrowRightPrimary: '/assets/icons/ic-arrow-right-primary.svg',
  icArrowRightBusiness: '/assets/icons/ic-arrow-right-white-bisnis.svg',
  icEyeOpen: '/assets/icons/open-eyes.svg',
  icEyeOpenWhite: '/assets/icons/ic_eye_open_white.svg',
  icEyeClose: '/assets/icons/close-eyes.svg',
  icEyeCloseWhite: '/assets/icons/ic-eye-close-white.svg',
  icTransferTwoTone: '/assets/icons/ic-transfer-two-tone.svg',
  icArrowDownWhite: '/assets/icons/ic-arrow-down-white.svg',
  icAgreementInactive: '/assets/icons/ic-agreement-inactive.svg',
  icSakuActive: '/assets/icons/ic-saku-active.svg',
  icSakuInactive: '/assets/icons/ic-saku-inactive.svg',
  icHomeActive: '/assets/icons/ic-home-active.svg',
  icHomeInactive: '/assets/icons/ic-home-inactive.svg',
  icUserActive: '/assets/icons/ic-user-active.svg',
  icUserInactive: '/assets/icons/ic-user-inactive.svg',
  icSearch: '/assets/icons/ic-search.svg',
  icSearchBlack: '/assets/icons/ic-search-black.svg',
  icRoundedBusinessBag: '/assets/icons/ic-rounded-business-bag.svg',
  icBusiness: '/assets/icons/ic-saku-bisnis.svg',
  icArrowRightSecondary: '/assets/icons/ic-arrow-right-secondary.svg',
  icFastActivation: '/assets/icons/ic-fast-activation.svg',
  icBulkTransfer: '/assets/icons/ic-bulk-transfer.svg',
  icMoneyInternal: '/assets/icons/ic-money-internal.svg',
  icAdmin: '/assets/icons/ic-admin-checklist.svg',
  icRadioChecklist: '/assets/icons/ic_radio_checklist.svg',
  icRadioChecklistOrange: '/assets/icons/ic_radio_checklist_orange.svg',
  icRadioUnchecklistNeutral: '/assets/icons/ic_radio_unchecklist_neutral.svg',
  icRadioCross: '/assets/icons/ic-radio-cross.svg',
  icArrowDownOrange: '/assets/icons/ic-arrow-down-orange.svg',
  icArrowUpOrange: '/assets/icons/ic-arrow-up-orange.svg',
  icArrowUpWhite: '/assets/icons/ic-arrow-up-white.svg',
  icCloseGray: '/assets/icons/ic-close-gray.svg',
  icCloseBlack: '/assets/icons/ic-close-black.svg',
  icCloseWhite: '/assets/icons/ic-close-white.svg',
  icPinDelete: '/assets/icons/ic-pin-delete.svg',
  icKeyboardDelete: '/assets/icons/ic-keyboard-delete.svg',
  icSakuUtamaCircle: '/assets/icons/ic-saku-utama-circle.svg',
  icSakuBujetCircle: '/assets/icons/ic-saku-bujet-circle.svg',
  icBank: '/assets/icons/ic-bank.svg',
  icRemoveNumpad: '/assets/icons/ic-remove-numpad.svg',
  icSakuWallet: '/assets/icons/ic-saku-wallet.svg',
  icDoubleRight: '/assets/icons/ic-double-right.svg',
  icCheckFilterOrange: '/assets/icons/ic-check-filter-orange.svg',
  icChecklistGreen: '/assets/icons/ic-checklist-green.svg',
  icTanyaRaya: '/assets/icons/ic-tanya-raya.svg',
  icSapaRaya: '/assets/icons/ic-sapa-raya.svg',
  icSapaRayaWhite: '/assets/icons/ic-sapa-raya-white.svg',
  icTanyaRayaBlue: '/assets/icons/ic_tanya_raya_blue.svg',
  icDanger: '/assets/icons/ic-danger.svg',
  icDangerOrange: '/assets/icons/ic-danger-orange.svg',
  icStarFavoritWhite: '/assets/icons/ic-star-favorit-white.svg',
  icStarFavoritYellow: '/assets/icons/ic-star-favorit-yellow.svg',
  icEditPencil: '/assets/icons/ic-edit-pencil.svg',
  icEditPencilGrey: '/assets/icons/ic-edit-pencil-grey.svg',
  icEditPencilOrange: '/assets/icons/ic-edit-pencil-orange.svg',
  icChecklist: '/assets/icons/ic-checklist.svg',
  icFavoriteEmpty: '/assets/icons/ic_favorite_empty.svg',
  icFavorite: '/assets/icons/ic_favorite.svg',
  icDelete: '/assets/icons/ic-delete.svg',
  icDeleteRed: '/assets/icons/ic-delete-red.svg',
  icMassTransfer: '/assets/icons/ic-mass-transfer.svg',
  icBenefitMassTransferFirst: '/assets/icons/ic-benefit-mass-transfer-1.svg',
  icBenefitMassTransferSecond: '/assets/icons/ic-benefit-mass-transfer-2.svg',
  icBenefitMassTransferThird: '/assets/icons/ic-benefit-mass-transfer-3.svg',
  icLogout: '/assets/icons/ic-logout.svg',
  icArrowRightOrange: '/assets/icons/ic-arrow-right-orange.svg',
  icDownloadWhite: '/assets/icons/ic-download-white.svg',
  icDownloadOrange: '/assets/icons/ic-download-orange.svg',
  icLogoutColor: '/assets/icons/ic-logout-color.svg',
  icFaq: '/assets/icons/ic-faq.svg',
  icCallColor: '/assets/icons/ic-call-color.svg',
  icSakuInfoFirst: '/assets/icons/ic-saku-info-first.svg',
  icSakuInfoSecond: '/assets/icons/ic-saku-info-second.svg',
  icSakuInfoThird: '/assets/icons/ic-saku-info-third.svg',
  icShare: '/assets/icons/ic-share.svg',
  icShareOrange: '/assets/icons/ic-share-orange.svg',
  icBlueBubble: '/assets/icons/ic-blue-bubble.svg',
  icOpenEye: '/assets/icons/ic-open-eye.svg',
  icCloseEye: '/assets/icons/ic-close-eye.svg',
  icDoneChecklist: '/assets/icons/ic-done-checklist.svg',
  icUnchecklist: '/assets/icons/ic-unchecklist.svg',
  icAddRound: '/assets/icons/ic-add-round.svg',
  icBarcode: '/assets/icons/ic_barcode.svg',
  icOnlineShop: '/assets/icons/ic-online-shop.svg',
  icOfflineShop: '/assets/icons/ic-offline-shop.svg',
  icShop: '/assets/icons/ic-shop-info.svg',

  //Saku Anak
  icTransferYouth: '/assets/saku_anak/icons/ic-transfer-youth.svg',
  icQrisYouth: '/assets/saku_anak/icons/ic-qris.svg',
  icHomeActiveYouth: '/assets/saku_anak/icons/ic-home-active.svg',
  icNotif: '/assets/saku_anak/icons/ic-notif.svg',
  icSakuAnak: '/assets/saku_anak/icons/ic-saku-anak.svg',
  icArrowRightWhite: '/assets/saku_anak/icons/ic-arrow-right-white.svg',
  icFingerprint: '/assets/saku_anak/icons/ic-fingerprint.svg',
  icPasswordYouth: '/assets/saku_anak/icons/ic-password-youth.svg',
  icCallYouth: '/assets/saku_anak/icons/ic-call-youth.svg',
  icFaqYouth: '/assets/saku_anak/icons/ic-faq-youth.svg',
  icLogoutYouth: '/assets/saku_anak/icons/ic-logout-youth.svg',
  icPhoneYouth: '/assets/saku_anak/icons/ic-phone-youth.svg',
  icWarning: '/assets/saku_anak/icons/ic-warning.svg',
  icUserActiveYouth: '/assets/saku_anak/icons/ic-user-youth.svg',
  icFilterBlack: '/assets/icons/ic-filter-black.svg',
  qrisMerchant: '/assets/icons/qris-merchant.svg',

  // Saku Related
  uangMasuk: '/assets/icons/ic_uang_masuk.svg',
  uangKeluar: '/assets/icons/ic_uang_keluar.svg',

  //Saku Bisnis Qris
  icFaqQris: '/assets/icons/ic-faq-qris.svg',
  icSapaRayaQris: '/assets/icons/ic-qris-sapa-raya.svg',
  icFnB: '/assets/icons/ic-fnb.svg',
  icService: '/assets/icons/ic-jasa.svg',
  icCharity: '/assets/icons/ic-badan-amal.svg',
  icBooks: '/assets/icons/ic-books.svg',
  icGadget: '/assets/icons/ic-gadget.svg',
  icFashion: '/assets/icons/ic-fashion.svg',
  icMedic: '/assets/icons/ic-medic.svg',
  icOtomotif: '/assets/icons/ic-otomotif.svg',
  icTax: '/assets/icons/ic-tax.svg',
  icEducation: '/assets/icons/ic-education.svg',
  icHousehold: '/assets/icons/ic-household.svg',
  icSpbu: '/assets/icons/ic-spbu.svg',
  icOthers: '/assets/icons/ic-others.svg',
  icFnBColored: '/assets/icons/ic-fnb-colored.svg',
  icServiceColored: '/assets/icons/ic-jasa-colored.svg',
  icCharityColored: '/assets/icons/ic-badan-amal-colored.svg',
  icBooksColored: '/assets/icons/ic-books-colored.svg',
  icGadgetColored: '/assets/icons/ic-gadget-colored.svg',
  icFashionColored: '/assets/icons/ic-fashion-colored.svg',
  icMedicColored: '/assets/icons/ic-medic-colored.svg',
  icOtomotifColored: '/assets/icons/ic-otomotif-colored.svg',
  icTaxColored: '/assets/icons/ic-tax-colored.svg',
  icEducationColored: '/assets/icons/ic-education-colored.svg',
  icHouseholdColored: '/assets/icons/ic-household-colored.svg',
  icSpbuColored: '/assets/icons/ic-spbu-colored.svg',
  icOthersColored: '/assets/icons/ic-others-colored.svg',
  icChecklistStroke: '/assets/icons/ic-checklist-stroke.svg',
  icQrisDetail: '/assets/icons/ic-qris-detail.svg',
  icPhoto: '/assets/icons/ic-photo.svg',
  icBisnisQris: '/assets/icons/ic-qris-bisnis.svg',
  icQris: '/assets/icons/ic-qris.svg',
  icQrisMenu: '/assets/icons/ic-qris-menu.svg',
  icQrisMenuInactive: '/assets/icons/ic-qris-menu-inactive.svg',
  icChevronRightWhite: '/assets/icons/ic-chevron-right-white.svg',
  icReceivedQris: '/assets/icons/ic-receive-qris.svg',
  icQrisDinamis: '/assets/icons/ic-qris-dinamis.svg',
  icBackspace: '/assets/icons/ic-backspace.svg',
  icTimeOut: '/assets/icons/ic-time-out.svg',
  icQrisIcon: '/assets/icons/icQrisIcon.svg',
  icRayaLogo: '/assets/icons/icRayaLogo.svg',

  //Raya Lottery
  icPoint: '/assets/icons/ic-point.svg',
  icLastTimeUpdated: '/assets/icons/ic-last-time-updated.svg',
  icTimeNeutral: '/assets/icons/ic-time-neutral.svg',
  icCouponBadge: '/assets/icons/ic-coupon-badge.svg',
  icPointBadge: '/assets/icons/ic-point-badge.svg',
  icTips1: '/assets/icons/ic-tips-1.svg',
  icTransferMenuRaya: '/assets/icons/ic-transfer-menu-raya.svg',
  icPlnIcon: '/assets/icons/ic-pln.svg',
  icPulsaIcon: '/assets/icons/ic-pulsa.svg',
  icPascabayarIcon: '/assets/icons/ic-pascabayar.svg',
  icCCIcon: '/assets/icons/ic-cc-icon.svg',
  icEWallet: '/assets/icons/ic-e-wallet.svg',
  icHowToIcon: '/assets/icons/ic-how-to-icon.svg',
  icArrowRightUndian: '/assets/icons/ic-arrow-right-undian.svg',
  icTingkatkanSaldo: '/assets/icons/ic-tingkatkan-saldo.svg',
  icRataSaldo: '/assets/icons/ic-rata-saldo.svg',
  icGiftbox: '/assets/icons/ic-giftbox.svg',
  icMoneyInOut: '/assets/icons/ic-money-in-out.svg',
  icHandphoneBerdiri: '/assets/icons/ic-handphone-berdiri.svg',
  icLogoPestaRaya: '/assets/icons/ic-logo-pesta-raya.svg',
  icKuponLottery: '/assets/icons/ic-kupon-lottery.svg',
  icGrandprize: '/assets/icons/ic_grandprize.svg',
  icJagaPintar: '/assets/icons/ic-jaga-pintar.svg',
  icKutipAtas: '/assets/icons/ic-kutip-atas.svg',
  icKutipBawah: '/assets/icons/ic-kutip-bawah.svg',
  icGiftboxSaku: '/assets/icons/ic_gifbox_saku.svg',

  // Banner Email Verification
  btnSpinnerLoader: 'assets/icons/btn-spinner-loader.svg',
};

export default Icons;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RatasBalance {
  utamaBudget: number;
  jagaPintar: number;
}

export interface Coupons {
  mobil?: number;
  motor?: number;
  gadget?: number;
  tabungan?: number;
}

interface LotteryState {
  point: number;
  lastUpdated: string;
  treePlanted: number;
  ratasBalance: RatasBalance;
  coupons: Coupons;
}

const initialState: LotteryState = {
  point: 0,
  lastUpdated: '',
  treePlanted: 0,
  ratasBalance: {
    utamaBudget: 0,
    jagaPintar: 0,
  },
  coupons: {},
};

export const lotterySlice = createSlice({
  name: 'lottery',
  initialState,
  reducers: {
    setLotteryData: (state, action: PayloadAction<LotteryState>) => {
      state.point = action.payload.point;
      state.lastUpdated = action.payload.lastUpdated;
      state.treePlanted = action.payload.treePlanted;
      state.ratasBalance = action.payload.ratasBalance;
      state.coupons = action.payload.coupons;
    },
    resetLotteryData: (state) => {
      state.point = initialState.point;
      state.lastUpdated = initialState.lastUpdated;
      state.treePlanted = initialState.treePlanted;
      state.ratasBalance = initialState.ratasBalance;
      state.coupons = initialState.coupons;
    },
  },
});

export const { setLotteryData, resetLotteryData } = lotterySlice.actions;
export default lotterySlice.reducer;
